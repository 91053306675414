.menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fafafa;
  width: 300px;
  height: 100%;
  padding: 15px;
  z-index: 1100;
}

.menu .menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #000;
}

.menu .menu-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--c4);
  color: var(--c4c);
  border-radius: 5px;
  height: 35px;
  width: 35px;
  font-size: 30px;
}

.menu .menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.menu .menu-buttons button {
  display: block;
  border: 0;
  background-color: var(--c4);
  color: var(--c4c);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
}
