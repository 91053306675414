.header {
	background-color: var(--c1);
	padding: 10px;
	padding-top: 25px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.header .header-section {
	display: flex;
	gap: 10px;
}

.button-labeled {
	position: relative;
}

.button-labeled .label {
	position: absolute;
	color: var(--c1c);
	font-size: 14px;
	top: -20px;
}

.button-labeled button {
	width: 130px;
	height: 35px;
	line-height: 15px;
	border: 2px solid var(--c2);
	background-color: var(--c2);
	color: var(--c2c);
}

.button-labeled.ellipsis button {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button-labeled.error button {
	border: 2px solid var(--c3);
	background-color: var(--c3);
	color: var(--c3c);
}

.button-labeled.disabled button {
	opacity: 50%;
}

.button-labeled.half button {
	width: 65px;
}

.menu-button {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	background-color: var(--c3);
	color: var(--c3c);
	font-size: 32px;
	border: 0;
}

.header-close-button {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	background-color: var(--c3);
	color: var(--c3c);
	font-size: 32px;
	border: 0;
}

.header-button {
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	background-color: var(--c3);
	color: var(--c3c);
	font-size: 16px;
	border: 0;
}
