.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  z-index: 400;
}

.popup .content {
  background-color: #ffffff;
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.popup .content .title {
  font-size: 20px;
  background-color: var(--c3);
  color: var(--c3c);
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.popup .content .title button {
  border: 0;
  margin: 0;
  background-color: var(--c1);
  color: var(--c1c);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
}

.popup .content .title button.close-button {
  width: 35px;
  font-size: 30px;
}

.popup .content .inner-content {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
  min-height: 250px;
}
