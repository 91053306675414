@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --c1: #213740;
  --c1c: #ffffff;
  --c2: #e9ecef;
  --c2c: #000000;
  --c3: #bf0426;
  --c3c: #ffffff;
  --c4: #eee;
  --c4c: #000;
  --c5: #eeeeee;
  --c5c: #000000;
  --confirmed: red;
  --delivering: blue;
  --filled: green;
}

html {
  height: 100vh;
  height: -webkit-fill-available;
  font-family: "Poppins", sans-serif !important;
}

body {
  padding: 0;
  margin: 0;
  height: -webkit-fill-available;
}

input,
select,
textarea {
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

input:not([type="checkbox"]) {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid var(--c4);
  width: 100%;
  display: block;
}

textarea {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid var(--c4);
  width: 100%;
  min-height: 150px;
  display: block;
}

select {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid var(--c4);
  width: 100%;
  display: block;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  border: 2px solid var(--c4);
  background-color: var(--c4);
  color: var(--c4c);
  font-weight: 500;
  border-radius: 5px;
}

.button.disabled {
  opacity: 0.8;
}

.button-1 {
  border: 2px solid var(--c1);
  background-color: var(--c1);
  color: var(--c1c);
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 500;
}

.button-3 {
  border: 2px solid var(--c3);
  background-color: var(--c3);
  color: var(--c3c);
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#columns {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

#map {
  flex-grow: 1;
  background-color: #ff000020;
}

.leaflet-container {
  height: 100%;
}

#sidebar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  flex-basis: 400px;
  height: 100%;
  position: relative;
  transition: flex-basis 0.2s ease-in-out;
}

#sidebar.expanded {
  flex-basis: 800px;
}

#sidebar .sidebar-button {
  position: absolute;
  top: 10px;
  left: -20px;
  width: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 400;
  border: 0;
  background-color: #fff;
}

#sidebar .sidebar-button i {
  font-size: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.list.mode-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.list-element {
  display: block;
  background-color: var(--c1);
  color: var(--c1c);
  padding: 15px;
  border-radius: 5px;
}

.list-element.table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.list-element.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.list-element.group .left {
  flex-grow: 1;
}

.list-element.table .center {
  text-align: center;
}

.list-element.table .buttons,
.list-element.group .buttons {
  display: flex;
  gap: 10px;
}

.list-element.table .buttons {
  justify-content: flex-end;
  align-items: center;
}

.list-element.table .buttons button,
.list-element.group .buttons button {
  border: 0;
  margin: 0;
  background-color: var(--c3);
  color: var(--c3c);
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
  text-transform: uppercase;
}

.list-element.group .buttons button img {
  height: 30px;
  stroke: var(--c3c);
}

.horizzontal-menu {
  margin-bottom: 15px;
  display: flex;
  overflow: auto;
  gap: 5px;
}

.horizzontal-menu button {
  background-color: var(--c5);
  color: var(--c5c);
  border: 0;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100px;
}

.horizzontal-menu button.active {
  background-color: var(--c4);
  color: var(--c4c);
}

.times-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.bold {
  font-weight: 500;
}

.small {
  font-size: 12px;
}

.input-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input {
  height: 36px;
}

.input-group button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--c4);
  color: var(--c4c);
  height: 36px;
  border-radius: 5px;
  white-space: nowrap;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form button {
  border: 0;
  margin: 0;
  background-color: var(--c4);
  color: var(--c4c);
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
  text-transform: uppercase;
}

.form button.success {
  background-color: var(--c3);
  color: var(--c3c);
}

.buttons-group {
  display: flex;
  gap: 10px;
}

.error-box {
  padding: 5px;
  background-color: red;
  color: white;
  font-size: 13px;
  white-space: pre-wrap;
}

.form-group .label {
  font-weight: 500;
}

.form-group .description {
  margin-top: 5px;
  font-size: 12px;
}

.form-line {
  display: grid;
  gap: 10px;
}
.form-line-3 {
  grid-template-columns: repeat(3, 1fr);
}

.list-light {
}

.list-light-item {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 0 10px;
}

.list-light-item.inverted {
  background-color: var(--c4);
  color: var(--c4c);
  border-bottom: 0;
}

.horizzontal-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.orders {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.orders .orders-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background-color: var(--c1);
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 1px;
  border-radius: 5px;
  font-family: "Poppins";
}

.orders .orders-header h1 {
  margin: 0;
  font-size: 24px;
}

.orders .orders-header i {
  font-size: 24px;
}

.orders .orders-list {
  overflow: auto;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
}

.orders .orders-list.expanded {
  max-height: 1000px;
}

.order-container {
  display: flex;
  margin-bottom: 1px;
}

.order-container .tag {
  flex-basis: 20px;
  flex-shrink: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.order-container .tag > div {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  white-space: nowrap;
  font-size: 12px;
  color: #fff;
}

.orders .order {
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  flex-grow: 1;
}

.orders .order .buttons {
  flex-basis: 150px;
  flex-shrink: 0;
  display: grid;
  gap: 5px;
}

#sidebar.expanded .order .buttons {
  grid-template-columns: 1fr 1fr;
  flex-basis: 300px;
}

#sidebar.expanded .orders .order .info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.orders .order .title {
  font-weight: 600;
}

.orders .order .date {
  white-space: nowrap;
}

.map-icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
}

.map-icon i {
  display: block;
}

.map-icon .fa-inverse {
  margin-top: -5px;
}

.driver-title {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--c1);
  color: var(--c1c);
  margin-bottom: 1px;
}

.driver-title > *:nth-child(2) {
  flex-grow: 1;
}

.driver-title button {
  border: 0;
  background-color: transparent;
  color: var(--c1c);
}

.job-title {
  padding: 5px;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
}

.job-title button {
  border: 0;
  background-color: transparent;
  color: var(--c1c);
}

#assignment-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  z-index: 1200;
}

#assignment-popup .content {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

#assignment-popup .content .title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

#assignment-popup .content .text {
  margin-bottom: 10px;
}

#assignment-popup .content textarea {
  resize: vertical;
}

#assignment-popup .content .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

#assignment-popup .content button {
  border: 0;
  padding: 10px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
}

#assignment-popup .content button.primary {
  background-color: var(--c3);
  color: var(--c3c);
}
