.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000090;
	z-index: 1200;
}

.loading .content {
	background-color: #fff;
	padding: 10px;
	border-radius: 5px;
}
