.settings {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff50;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 30px;
	z-index: 1100;
}

.settings .settings-container {
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	border: 1px solid #000;
	display: flex;
	flex-direction: column;
}

.settings .settings-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	background-color: var(--c4);
	color: var(--c4c);
}

.settings .settings-header button {
	border: 0;
	margin: 0;
	background-color: var(--c3);
	color: var(--c3c);
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
}

.settings .settings-columns {
	display: flex;
	flex-grow: 1;
	height: 100%;
	overflow: hidden;
}

.settings .settings-menu {
	border-right: 1px solid #000;
	padding: 10px;
	width: 200px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.settings .settings-menu button {
	border: 0;
	margin: 0;
	background-color: var(--c4);
	color: var(--c4c);
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}

.settings .settings-content {
	padding: 10px;
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 100%;
}
